body {
    overflow-y: overlay !important;
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
    background-color: whitesmoke;
}

/* ScrollBar custom sabart */
body ::-webkit-scrollbar {
    width: 6px;
}
body ::-webkit-scrollbar-thumb {
    background: #888;
}
body ::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Custom Appeareance */

.sabart-logo-no-auth {
    margin: 20px 0px;
    width: 270px;
}

.no-auth-page {
    background-color: white;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px 2px #000000;
    box-shadow: 0px 0px 15px 2px #000000;
}

.title-no-auth {
    text-transform: uppercase;
    font-weight: bolder;
    color:  #e10026;
    padding: 5px 20px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
}

.text-no-auth {
    color:  black;
    padding: 5px 20px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
}

/**/