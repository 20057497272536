.layout-container {
    padding-left: 10px;
    padding-right: 10px;
}
.filter-box {
    padding-top: 15px;
}
.standard-buttons {
    background-color: #e10026 !important;
    border-color: #e10026 !important;
    color: white !important;
}
.bottone-ricerca {
    background-color: #e10026 !important;
    border-color: #e10026 !important;
    color: white !important;
}
.bottone-info {
    margin-top: 6px;
    background-color: #e10026 !important;
    border-color: #e10026 !important;
    color: white !important;
    border-radius: 50% !important;
    width:  35px !important;
    height: 35px !important;
    padding: 10px 5px !important;
}
.bottone-info:hover,
.custom-dialog-close-button:hover {
    -webkit-box-shadow: 0px 0px 5px 1px #000000 !important;
    box-shadow:         0px 0px 5px 1px #000000 !important;
}
.custom-dialog-close-button {
    z-index: 1;
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    font-size: 15px;
    cursor: pointer;
    width:  30px !important;
    height: 30px !important;
    background-color: #e10026 !important;
    border-color: #e10026 !important;
    color: white !important;
    border-radius: 50% !important;
}
.custom-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.custom-dialog-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-dialog-content img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
.filtro-menu .k-button-solid-primary {
    background-color: #e10026 !important;
    border-color: #e10026 !important;
    color: white !important;
}
.filtro-menu .k-button-solid-primary.k-disabled {
    background-color: #333333 !important;
    border-color: #333333 !important;
    color: white !important;
}
.filtro-menu .k-button-solid-primary:hover {
    color: white !important;
    background-color: #333333 !important;
    border-color: #333333 !important;
}
.standard-buttons:hover {
    color: white !important;
    background-color: #333333 !important;
    border-color: #333333 !important;
}
.standard-inputs{
    border-width: 0 0 1px 0 ;
    border-color: #333333 !important;
}
.standard-inputs.k-focus{
    border-color: #e10026 !important;
}
.label-ricerca-corrente {
    font-size: 18px;
    position: relative;
    top:5px;
    font-weight: lighter !important;
    /*!*left: 50%;*!*/
    /*-ms-transform: translate(-50%, -50%);*/
    /*transform: translate(-50%, -50%);*/
}

.layout-header-buttons {
    margin-left: 5px;
    margin-right: 5px;
}
.img-esploso-container {
    background-color: white;
    text-align: center;
    margin-top: 2vh;
    border-radius: 5px;
    padding: 5px;
    -webkit-box-shadow: 0px 0px 15px 2px #000000;
    box-shadow: 0px 0px 15px 2px #000000;
}
.img-container-zoomed {
    justify-content: center;
    text-align: center;
}
.img-esploso {
    height: -webkit-fill-available;
}
.img-esploso-zoomed {
    height: 99vh;
}
.zoom-button {
    float:right;
}
.zoom-button-zoomed {
    float:right;
    margin: 10px;
}
.zoom-button-zoomed .k-icon {
    font-size: 50px !important;
}
.zoom-button .k-icon {
    font-size: 25px !important;
}
.spinner-image{
    position: relative;
    top: 50%;
    /*left: 50%;*/
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

}
.search-bar .standard-inputs {
    font-size: 20px;
}

.img-esploso-container .react-transform-wrapper,
.img-esploso-container .react-transform-component
{
    justify-content: center!important;
    width:  100% !important;
    height: 100% !important;
}

.tools {
    z-index: 2;
    position: absolute;
}
.tools .k-button {
    margin-right: 10px;
}
.tools .k-icon {
    font-size: 25px !important;
}
.sabart-logo {
    width: 250px;
}
.mtd-logo {
    width: 120px;
}
.carraro-logo {
    margin-top: 15px;
    width: 180px;
}
.search-bar-lastest {
    overflow: clip;
    /*padding-top: 5px;*/
}
.bottoni-ricerche-recenti {
    margin: 0px 5px 0px 5px;
    border-color: #e10026 !important;
}
.bottoni-ricerche-recenti-icon {
    color:  #e10026 !important;
    font-size: 20px;
    margin-left: 5px;
    margin-right: -7px !important;
}
.filter-button {
    float: right;
    margin-left: 10px;

}

@media only screen and (max-width: 1200px) {
    .img-esploso-container {
        width: 94vw;
    }
    .search-bar .standard-inputs {
        font-size: 15px;
    }

    .sabart-logo {
        width: 190px;
    }
    .mtd-logo {
        width: 90px;
    }
    .carraro-logo {
        width: 120px;
    }
}

@media only screen and (max-width: 100px) {

    .custom-dialog-content img {
        max-width:  80% !important;
        max-height: 80% !important;
        object-fit: contain;
    }
}
@media only screen and (max-width: 900px) {
    .img-esploso-container {
        width: 92vw;
    }
}
@media only screen and (max-width: 700px) {

}