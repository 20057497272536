/* ScrollBar custom sabart - dentro griglia */
.articles-grid ::-webkit-scrollbar {
    height: 12px;
}

.zoom-dialog .k-window-content.k-dialog-content
{
    padding: 0px !important
}
.tabstrip-content {
    /*justify-content: center;*/
    /*text-align: center !important;*/
}
.k-tabstrip-items-wrapper .k-item.k-active::after {
    border-color: #e10026 !important;
}
.k-loader-primary {
    color: #e10026 !important;
}
.search-bar .k-input{
    width:80% ;
}

.exploded-drawing-grid .k-grid-content tr:hover{
    background-color: lightgrey !important;

}

.k-grid-content tr {
    cursor: pointer;
    background-color: whitesmoke !important;
}
.k-grid-content tr  .k-grid-content-sticky{
    cursor: pointer;
    background-color: whitesmoke !important;
}

/*.k-grid-content tr  .k-grid-content-sticky:hover{*/
/*    background-color: rgba(225, 0, 38,0.7) !important;*/
/*}*/

.k-grid-content .k-alt {
    background-color: white !important;
}
.k-grid-content .k-alt  .k-grid-content-sticky{
    background-color: white !important;
}
.bottone-svuota-ricerca .k-button-text{
    font-size: 12px !important;
}
.bottone-svuota-ricerca .k-icon{
    font-size: 22px !important;
}
.svuota-text {
    padding: 4px;
    margin-top: 2px;
}
/*.k-grid-content .k-alt  .k-grid-content-sticky:hover{*/
/*    background-color: rgba(225, 0, 38,0.7) !important;*/
/*}*/

@media only screen and (max-width: 900px) {
    body {
        overflow-x: hidden !important;
        overflow-y: overlay !important;
    }
    .sabart-logo {
        /*width: 150px;*/
    }
    .search-bar {
        margin-top: 5px;
    }
    .container-logo {
        text-align: center;
    }
    .search-bar .k-input{
        width:100% ;
    }
    .k-appbar.k-appbar-top.k-appbar-static.k-appbar-light {
        width: fit-content !important;
    }
}